import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import transformationJson from '../../mappings/transformation.json';
import styles from './style.css';

const DrivingContent = (props) => {
  const [title1, setTitle1] = React.useState("");
  const [title2, setTitle2] = React.useState("");
  const [title3, setTitle3] = React.useState("");

  const [content1, setContent1] = React.useState("");
  const [content2, setContent2] = React.useState("");
  const [content3, setContent3] = React.useState("");

  const [imageUrl1, setImageUrl1] = React.useState("");
  const [imageUrl2, setImageUrl2] = React.useState("");
  const [imageUrl3, setImageUrl3] = React.useState("");

  React.useEffect(() => {
    let changeableStateObj;
    changeableStateObj = transformationJson["mindConstruct-data"].changeableState.find(o => o.value === props.firstAreaData.changeableState);
    setTitle1(changeableStateObj.text);
    changeableStateObj = transformationJson["mindConstruct-data"].changeableState.find(o => o.value === props.secondAreaData.changeableState);
    setTitle2(changeableStateObj.text);
    changeableStateObj = transformationJson["mindConstruct-data"].changeableState.find(o => o.value === props.thirdAreaData.changeableState);
    setTitle3(changeableStateObj.text);

    const contentArray = ["Fixed: I am not williing to change this part of my being.", "Static: I can change this part of my being however I feel sluggish about it.", "Flexible: I am totally willing to change this part of my being."];
    setContent1(contentArray[props.firstAreaData.changeableState]);
    setContent2(contentArray[props.secondAreaData.changeableState]);
    setContent3(contentArray[props.thirdAreaData.changeableState]);

    const imageArray = ["/images/power1.png", "/images/power2.png", "/images/power3.png"];
    setImageUrl1(imageArray[props.firstAreaData.changeableState]);
    setImageUrl2(imageArray[props.secondAreaData.changeableState]);
    setImageUrl3(imageArray[props.thirdAreaData.changeableState]);
  }, [props]);

  return (
    <Box sx={{ flexGrow: 1, px: "10px"}} className="DrivingContentClass">
      <Grid container>
        <Grid item md={4} xs={12}>
          <img src="/images/qIcon1.png" alt="question" />
          <Typography className="questionTitle">
            How do I see myself?
          </Typography>
          <img className="current1" src="/images/current1.png" alt="current1" />
          <img className="power1" src={imageUrl1} alt="power1" />
          <Typography className="questionTitle1">
            The law of who I am that rules over what I can be is...
          </Typography>
          <Card className="card">
            <CardContent>
              <Typography className="title">
                {title1}
              </Typography>
              <Typography className="content">
                {content1}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4} xs={12}>
          <img className="question" src="/images/qIcon2.png" alt="question" />
          <Typography className="questionTitle">
            How I see my reality?
          </Typography>
          <img className="current1" src="/images/current2.png" alt="current2" />
          <img className="power2" src={imageUrl2} alt="power2" />
          <Typography className="questionTitle1">
            What I am being that sets the boundaries & energy I live by is...
          </Typography>
          <Card className="card">
            <CardContent>
              <Typography className="title">
                {title2}
              </Typography>
              <Typography className="content">
                {content2}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4} xs={12}>
          <img className="question" src="/images/qIcon3.png" alt="question" />
          <Typography className="questionTitle">
            How do I interact with life?
          </Typography>
          <img className="current1" src="/images/current3.png" alt="current3" />
          <img className="power3" src={imageUrl3} alt="power3" />
          <Typography className="questionTitle1">
            How I am being that guides my behaviour & activities in everyday life is...
          </Typography>
          <Card className="card">
            <CardContent>
              <Typography className="title">
                {title3}
              </Typography>
              <Typography className="content">
                {content3}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DrivingContent;