import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import eventJson from '../../mappings/feedback_event.json';
import styles from './style.css';

const MindContent = (props) => {
  const [attentionReactionText, setAttentionReactionText] = React.useState("");
  const [eventChallenged, setEventChallenged] = React.useState("");
  const [eventImportance, setEventImportance] = React.useState("");

  React.useEffect(() => {
    const attentionReactionObj = eventJson.attentionReaction.find(o => o.value === props.attentionReaction);
    setAttentionReactionText(attentionReactionObj.text);

    const eventChallengedObj = eventJson.eventChallenged.find(o => o.value === props.eventChallenged);
    setEventChallenged(eventChallengedObj.text);
  
    const eventImportanceObj = eventJson.eventImportance.find(o => o.value === props.eventImportance);
    setEventImportance(eventImportanceObj.text);
  }, [props.attentionReaction]);

  return (
    <Box sx={{ flexGrow: 1, mt: "50px"}} className="MindContentClass">
      <Grid container>
        <Grid item md={5} xs={12}>
          <Typography className="triggerTitle">
            Trigger Event
          </Typography>
          <Typography className="triggerContent">
            This event gives me feedfback about me & is connected to an ...
          </Typography>
          <Card className="triggerCard">
            <CardContent>
              <img className="trigger1" src={props.mindTravelEvent ? "/images/trigger2.png" : "/images/trigger1.png"} alt="trigger1" /> 
              <Typography className="title" sx={{mt: "-40px"}}>
                {props.mindTravelEvent ? "Internal" : "External"} trigger event: 
              </Typography>
              <Typography className="content">
                {
                  props.mindTravelEvent ? 
                    "Took place within my mind via my thoughts & feelings"
                  : 
                    "Took place in the outside world in my day to day life"
                }
              </Typography>
              <Typography className="title">
                {eventChallenged}  &
              </Typography>
              <Typography className="title">
                {eventImportance}
              </Typography>
              <Typography className="content attentionReaction">
                <span>How much did this affect me?</span> <span>{attentionReactionText}</span>
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={7} xs={12}>
          <img className="comma" src="/images/comma.png" />
          <Typography className="quoteTitle">
            {props.quoteText}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default MindContent;