import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Carousel from 'react-material-ui-carousel';
import Button from '@mui/material/Button';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import orientationJson from '../../mappings/orientation.json';
import transformationJson from '../../mappings/transformation.json';
import aimJson from '../../mappings/aim.json';
import styles from './style.css';

const AimContent = (props) => {
  const count = 4;
  const [number, setNumber] = React.useState(0);
  const [firstTitle, setFirstTitle] = React.useState("");
  const [middleTitle, setMiddleTitle] = React.useState("");
  const [lastTitle, setLastTitle] = React.useState("");
  const [firstImageUrl, setFirstImageUrl] = React.useState("");
  const [middleImageUrl, setMiddleImageUrl] = React.useState("");
  const [lastImageUrl, setLastImageUrl] = React.useState("");
  const [identityPartHeader, setIdentityPartHeader] = React.useState("");
  const [content, setContent] = React.useState("");
  const [content1, setContent1] = React.useState("");
  const [thirdData, setThirdData] = React.useState([]);
  const [journeyType, setJourneyType] = React.useState("");
  const [journeyTypeUrl, setJourneyTypeUrl] = React.useState("");

  const handleNext = () => {
    if(number == count - 1) {
      return;
    } else {
      setNumber(number + 1);
    }
  };

  const handlePrevious = () => {
    if(number == 0) {
      return;
    } else {
      setNumber(number - 1);
    }
  };

  React.useEffect(() => {
    const firstTitleArray = ["How do I see myself", "How I see my reality", "How I interact with life?"];
    const firstImageUrlArray = ["/images/qIcon1.png", "/images/qIcon2.png", "/images/qIcon3.png"];
    setMiddleTitle(firstTitleArray[props.data.areaForChange]);
    setMiddleImageUrl(firstImageUrlArray[props.data.areaForChange]);
    switch(props.data.areaForChange) {
    case 0:
      setFirstTitle(firstTitleArray[1]);
      setFirstImageUrl(firstImageUrlArray[1]);
      setLastTitle(firstTitleArray[2]);
      setLastImageUrl(firstImageUrlArray[2]);
      break;
    case 1:
      setFirstTitle(firstTitleArray[0]);
      setFirstImageUrl(firstImageUrlArray[0]);
      setLastTitle(firstTitleArray[2]);
      setLastImageUrl(firstImageUrlArray[2]);
      break;
    case 2:
      setFirstTitle(firstTitleArray[0]);
      setFirstImageUrl(firstImageUrlArray[0]);
      setLastTitle(firstTitleArray[1]);
      setLastImageUrl(firstImageUrlArray[1]);
      break;
    default:
      break;
    }

    const identityPartObj = orientationJson.mindLocationData.identityPart.find(o => o.value === props.identityPart);
    setIdentityPartHeader(identityPartObj.category);

    if(props.data.areaForChange === 0) {
      if(props.firstAreaData.state === 2) {
        const attitudeObj = transformationJson["mindConstruct-data"].attitude.find(o => o.value === props.firstAreaData.attitude);
        setContent(attitudeObj.text);
      } else {
        setContent(props.firstAreaData.attribute);
      }
    } else if(props.data.areaForChange === 1) {
      if(props.secondAreaData.state === 2) {
        const attitudeObj = transformationJson["mindConstruct-data"].attitude.find(o => o.value === props.secondAreaData.attitude);
        setContent(attitudeObj.text);
      } else {
        setContent(props.secondAreaData.attribute);
      }
    } else {
      if(props.thirdAreaData.state === 2) {
        const attitudeObj = transformationJson["mindConstruct-data"].attitude.find(o => o.value === props.thirdAreaData.attitude);
        setContent(attitudeObj.text);
      } else {
        setContent(props.thirdAreaData.attribute);
      }
    }
    
    const newMeObj = transformationJson["mindConstruct-data"].attitude.find(o => o.value === props.data.newMe);
    setContent1(newMeObj.text);

    const lifeImageArray = ["/images/life1.png", "/images/life2.png", "/images/life3.png", "/images/life4.png", "/images/life5.png", "/images/life6.png"];
    let keys = Object.keys(props.data.lifeAreas);
    let values = Object.values(props.data.lifeAreas);
    let tempData = [];
    keys.forEach((item, index) => {
      const areaObj = aimJson.lifeAreas.area.find(o => o.value === parseInt(item));
      const priorityObj = aimJson.lifeAreas.priority.find(o => o.value === values[index]);
      const temp = {
        title: areaObj.text,
        content: priorityObj.text,
        imageUrl: lifeImageArray[item]
      }
      tempData.push(temp);
    });
    setThirdData(tempData);

    const journeyTypeObj = aimJson.journeyType.find(o => o.value === props.data.journeyType);
    const journeyTypeUrlArray = ["/images/aim1.png", "/images/aim2.png", "/images/aim3.png", "/images/aim4.png", "/images/aim5.png", "/images/aim6.png", "/images/aim7.png", "/images/aim8.png", "/images/aim9.png", "/images/aim10.png", "/images/aim11.png"];
    setJourneyType(journeyTypeObj.text);
    setJourneyTypeUrl(journeyTypeUrlArray[props.data.journeyType]);
  }, [props]);

  return (
    <Box sx={{ flexGrow: 1}} className="AimContentClass">
      <Grid container>
        <Grid item md={12} xs={12}>
          <Carousel
            autoPlay={false}
            indicators={false}
            cycleNavigation={false}
            index={number}
          >
            <Card className="card">
              <CardContent>
                <Typography className="firstTitle">
                  I want to change...
                </Typography>
                <Typography className="firstContent">
                  The part of my self concept that I want to change is...
                </Typography>
                <Box className='firstContentBox'>
                  <Box className='firstcardbox'>
                    <img className='qIcon' src={firstImageUrl} alt="qIcon" />
                    <Typography className="firstIconTitle">
                      {firstTitle}
                    </Typography>
                  </Box>
                  <Box>
                    <img className='qIcon' src={middleImageUrl} alt="qIcon" />
                    <Typography className="firstIconTitle">
                      {middleTitle}
                    </Typography>
                  </Box>
                  <Box className='lastcardbox'>
                    <img className='qIcon' src={lastImageUrl} alt="qIcon" />
                    <Typography className="firstIconTitle">
                      {lastTitle}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
            <Card className="card">
              <CardContent>
                <Box className="secondBox1">
                  <Typography className="secondHeader">
                    The old me
                  </Typography>
                  <div className="secondDiv">
                    <Typography className="secondContent">
                      I am a
                    </Typography>
                    <Typography className="secondTitle">
                      {identityPartHeader}
                    </Typography>
                    <Typography className="secondContent">
                      based on
                    </Typography>
                    <Typography className="secondTitle">
                      {content}
                    </Typography>
                    <Typography className="secondContent">
                      which governs my being
                    </Typography>
                  </div>
                </Box>
                <Box className="secondBox2">
                  <Typography className="secondHeader">
                    The new me
                  </Typography>
                  <div className="secondDiv">
                    <Typography className="secondContent">
                      to support becoming the new me
                    </Typography>
                    <Typography className="secondTitle">
                      The new me attitude is...
                    </Typography>
                    <Typography className="secondBlueTitle">
                      {content1}
                    </Typography>
                    <Typography className="secondContent">
                      What do I want to focus on & change? 
                    </Typography>
                    <Typography className="secondTitle">
                      {props.data.newMeDesc}
                    </Typography>
                  </div>
                </Box>
              </CardContent>
            </Card>
            <Card className="card">
              <CardContent>
                <Typography className="title">
                  Life areas
                </Typography>
                <Typography className="content">
                  Porttitor habitant proin tristique platea sed aenean ut. Habitant vel tempus nulla fermentum rhoncus ultrices. Semper mauris amet elementum non amet consectetur leo at.
                </Typography>
                <Box className="cardBox">
                  {
                    thirdData.map((item, index) => {
                      return (
                        <Grid className="cardBoxGrid" key={index}>
                          <img className='aim2' src={item.imageUrl} alt="aim2" />
                          <Typography className="smallTitle1">
                            {item.title}
                          </Typography>
                          <Typography className="smallTitle2">
                            {item.content}
                          </Typography>
                        </Grid>
                      );
                    })
                  }
                </Box>
              </CardContent>
            </Card>
            <Card className="card">
              <CardContent>
                <Typography className="title">
                  My destination
                </Typography>
                <Typography className="content">
                  Porttitor habitant proin tristique platea sed aenean ut. Habitant vel tempus nulla fermentum rhoncus ultrices. Semper mauris amet elementum non amet consectetur leo at.
                </Typography>
                <img className='aim' src={journeyTypeUrl} alt="aim" />
                <Typography className="smallTitle">
                  {journeyType}
                </Typography>
              </CardContent>
            </Card>
          </Carousel>
        </Grid>
        <Grid item md={12} xs={12}>
          <Button
            className={`previous ${number === 0 ? "unactive" : ""}`}
            onClick={handlePrevious}
          >
            <KeyboardArrowLeftIcon />
          </Button>
          <Button 
            className={`next ${number === count - 1 ? "unactive" : ""}`}
            onClick={handleNext}
          >
            <KeyboardArrowRightIcon />
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AimContent;