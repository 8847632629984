import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import conclusionJson from '../../mappings/conclusion.json';
import styles from './style.css';

const ArriveContent = (props) => {
  const [targetDestinationReached, setTargetDestinationReached] = React.useState("");
  const [distanceToDestination, setDistanceToDestination] = React.useState("");
  const [targetContent, setTargetContent] = React.useState("");

  React.useEffect(() => {
    if(Object.keys(props.data).length > 0) {
      const targetDestinationReachedObj = conclusionJson.targetDestinationReached.find(o => o.value === props.data.targetDestinationReached);
      const targetArray = targetDestinationReachedObj.text.split('\n');
      if(targetArray.length > 0) {
        setTargetDestinationReached(targetArray[0]);
        setTargetContent(targetArray[1]);
      }

      if(props.data.distanceToDestination) {
        const distanceToDestinationObj = conclusionJson.targetDestinationReached.find(o => o.value === props.data.distanceToDestination);
        setDistanceToDestination(distanceToDestinationObj.text);
      }
    }
  }, [props]);

  return (
    <Box sx={{ flexGrow: 1}} className="ArriveContentClass">
      <Grid container spacing={1} className="container">
        <Grid item md={12} xs={12}>
          <Stack direction="row" className="stack">
            <img className="science" src="/images/arrive.png" alt="science" />
            <Box className="box">
              <Typography className="content">
                Destination landing
              </Typography>
              <Typography className="bigTitle">
                {targetDestinationReached}
              </Typography>
              <Typography className="smallTitle">
                {targetContent}
              </Typography>
              <Typography className="content">
                {distanceToDestination}
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ArriveContent;