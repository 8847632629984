import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import styles from './style.css';

const StoreContent = () => {
  
  return (
    <Box sx={{ flexGrow: 1}} className="StoreContentClass">
      <Grid container>
        <Grid item md={12} xs={12}>
          <Card className="card">
            <CardContent>
              <Typography className="title">
                Self evolution starts now!
              </Typography>
              <Typography className="content">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud exercitation.
              </Typography>
              <Stack spacing={1} direction="row" className="stack">
                <Button
                  variant="outlined"
                >
                  <img src='/images/appstore.png' />
                </Button>
                <Button
                  variant="outlined"
                >
                  <img src='/images/googleplay.png' />
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default StoreContent;