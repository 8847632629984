import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import styles from './style.css';

const Experiment = (props) => {
  return (
    <Box sx={{ flexGrow: 1}} className="ExperimentClass">
      <Grid container>
        <Grid item md={12} xs={12}>
          <img className="brand" src={`/images/${props.image}.png`} alt="brand" />

          <Typography className="title">
            {props.title.split("$").map((i, key) => {
              return <div key={key}>{i}</div>;
            })}
          </Typography>
          <Typography className="content">
            {props.content.split("$").map((i, key) => {
              return <div key={key}>{i}</div>;
            })}
          </Typography>
          <img className="line" src="/images/Line1.png" alt="line" />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Experiment;