import React, { useState } from 'react';

import { collectionGroup, getDocs, query, where, getDoc, doc } from 'firebase/firestore/lite';
import { db, storage } from './firebase';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { ref, getDownloadURL } from "firebase/storage";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Header from './components/Header/Header';
import FollowJourney from './components/FollowJourney/FollowJourney';
import SubOptionTitle from './components/SubOptionTitle/SubOptionTitle';
import MindContent from './components/MindContent/MindContent';
import CurrentContent from './components/CurrentContent/CurrentContent';
import DrivingContent from './components/DrivingContent/DrivingContent';
import AimContent from './components/AimContent/AimContent';
import MethodContent from './components/MethodContent/MethodContent';
import StoreContent from './components/StoreContent/StoreContent';
import VersionContent from './components/VersionContent/VersionContent';
import Common from './components/Common/Common';
import Footer from './components/Footer/Footer';
import Travel from './components/Travel/Travel';
import LandingContent from './components/LandingContent/LandingContent';
import ArriveContent from './components/ArriveContent/ArriveContent';
import Experiment from './components/Experiment/Experiment';
import Error from './components/404/404';
import HighlightContent from './components/HighlightContent/HighlightContent';
import './App.css';

function App(props) {
  const [loading, setLoading] = React.useState(true);

  //experiment
  const [experimentImage, setExperimentImage] = React.useState("");
  const [experimentTitle, setExperimentTitle] = React.useState("");
  const [userName, setUserName] = React.useState("");

  //event
  const [mindTravelEvent, setMindTravelEvent] = React.useState(false);
  const [attentionReaction, setAttentionReaction] = React.useState(0);
  const [quoteText, setQuoteText] = React.useState("");
  const [eventAttachments, setEventAttachments] = React.useState({});
  const [eventChallenged, setEventChallenged] = React.useState(0);
  const [eventImportance, setEventImportance] = React.useState(0);
  const [attentionEventExternal, setAttentionEventExternal] = React.useState(0);
  const [attentionEventInternal, setAttentionEventInternal] = React.useState(0);

  //orientation
  const [identityPart, setIdentityPart] = React.useState(0);
  const [identityArea, setIdentityArea] = React.useState(0);
  const [identityAspect , setIdentityAspect ] = React.useState(0);
  const [aspectProblem, setAspectProblem] = React.useState("");
  const [motivatorRelation , setMotivatorRelation ] = React.useState(0);
  const [angleOfPerspective, setAngleOfPerspective] = React.useState("");
  const [distance, setDistance ] = React.useState(0);
  
  const [perceptionResponse, setPerceptionResponse] = React.useState(0);
  const [perspectiveResponse, setPerspectiveResponse] = React.useState(0);
  const [presenceResponse, setPresenceResponse] = React.useState(0);
  const [orientationAttachments, setOrientationAttachments] = React.useState({});

  //transformation
  const [firstAreaData, setFirstAreaData] = React.useState({
    state: 0,
    attitude: 0,
    attribute: "",
    mindForce: {
      value: -1,
    },
    lockedReason: 0,
    changeableState: 0,
  });
  const [secondAreaData, setSecondAreaData] = React.useState({
    state: 0,
    attitude: 0,
    attribute: "",
    mindForce: {
      value: -1,
    },
    lockedReason: 0,
    changeableState: 0,
  });
  const [thirdAreaData, setThirdAreaData] = React.useState({
    state: 0,
    attitude: "",
    attribute: "",
    mindForce: {
      value: -1,
    },
    lockedReason: 0,
    changeableState: 0,
  });
  const [transformationAttachments, setTransformationAttachments] = React.useState({});
  const [accepted, setAccepted] = useState(1);

  //Aim
  const [aimData, setAimData] = React.useState({
    areaForChange: 0,
    oldMe: 0,
    newMe: 0,
    newMeDesc: "",
    lifeAreas: {},
    journeyType: 0,
    attachments: {},
  });

  //method
  const [methodData, setMethodData] = React.useState({
    reminder: {},
    attachments: {},
  });

  //Conclusion
  const [conclusionData, setConclusionData] = React.useState({});

  const getExperimentData = async () => {
    const docRef = doc(db, "users", props.userId, "experiments", props.experimentId);
    getDoc(docRef).then(docSnap => {
      if (docSnap.exists()) {
        const experimentData = docSnap.data();
        if(experimentData.image !== "") {
          const link = 'gs://foq-u-app.appspot.com/' + props.userId + '/' + props.experimentId + '/experiment/' + experimentData.image.split("/")[experimentData.image.split("/").length - 1];
          getDownloadURL(ref(storage, link))
          .then((url) => {
            setExperimentImage(url);
          })
          .catch((error) => {
            // Handle any errors
          });
        }
        setExperimentTitle(experimentData.name);
      }
    });

    const docRef1 = doc(db, "sharing", props.userId, "experiment_shared_data", props.experimentId, "user", props.userId);
    getDoc(docRef1).then(docSnap => {
      if (docSnap.exists()) {
        setUserName(docSnap.data().name);
      }
    });
  };

  const getEventData = async () => {
    const eventData = await getDocs(query(collectionGroup(db, "feedback_event"), where('experimentId', '==', props.experimentId), where('type', '==', "Analysis")));
    if(eventData.docs.length > 0) {
      const data = JSON.parse(eventData.docs[0].data().inputData);
      const attachments = JSON.parse(eventData.docs[0].data().attachments);
      setMindTravelEvent(data.mindTravelEvent === 0 ? false : true);
      setAttentionReaction(parseInt(data.attentionReaction));
      setQuoteText(data.descriptionEvent.description);
      setEventChallenged(data.eventChallenged);
      setEventImportance(data.eventImportance);
      setAttentionEventExternal(data.attentionEventExternal);
      setAttentionEventInternal(data.attentionEventInternal);
      setEventAttachments(attachments);
    }
  };

  const getOrientationData = async () => {
    const orientationData = await getDocs(query(collectionGroup(db, "orientation"), where('experimentId', '==', props.experimentId), where('type', '==', "Analysis")));
    if(orientationData.docs.length > 0) {
      const mindLocationData = JSON.parse(orientationData.docs[0].data().mindLocationData);
      const attachments = JSON.parse(orientationData.docs[0].data().attachments);
      setIdentityPart(mindLocationData.identityPart);
      setIdentityArea(mindLocationData.identityArea);
      setIdentityAspect(JSON.parse(mindLocationData.identityAspect).value);
      setAspectProblem(mindLocationData.aspectProblem);
      setMotivatorRelation(mindLocationData.motivatorRelation);
      setAngleOfPerspective(mindLocationData.angleOfPerspective);
      setDistance(mindLocationData.distance);

      const mindNavigationData = JSON.parse(orientationData.docs[0].data().mindNavigationData);
      
      const mindForceData = JSON.parse(orientationData.docs[0].data().mindForceData);
      setPerceptionResponse(mindForceData.perceptionResponse);
      setPerspectiveResponse(mindForceData.perspectiveResponse);
      setPresenceResponse(mindForceData.presenceResponse);
      setOrientationAttachments(attachments);
    }
  }

  const getTransformationData = async () => {
    const transformationData = await getDocs(query(collectionGroup(db, "transformation"), where('experimentId', '==', props.experimentId)));
    if(transformationData.docs.length > 0) {
      const inputData = JSON.parse(transformationData.docs[0].data().inputData);
      const mindConstructData = JSON.parse(inputData["mindConstruct-data"]);
      const attachments = JSON.parse(transformationData.docs[0].data().attachments);
      mindConstructData.forEach((item, index) => {
        const mindForceData = JSON.parse(item.mindForce);
        const data = {
          state: item.state,
          attitude: item.attitude,
          attribute: item.attribute,
          mindForce: mindForceData,
          lockedReason: item.lockedReason,
          changeableState: item.changeableState
        }
        if(index === 0) {
          setFirstAreaData(data);
        } else if(index === 1) {
          setSecondAreaData(data);
        } else {
          setThirdAreaData(data);
        }
      });
      setTransformationAttachments(attachments);
      setAccepted(transformationData.docs[0].data().accepted);
    }
  }

  const getAimData = () => {
    const docRef = doc(db, "users", props.userId, "aim", props.experimentId);
    getDoc(docRef).then(docSnap => {
      if (docSnap.exists()) {
        const formData = JSON.parse(docSnap.data().formData);
        const lifeAreas = JSON.parse(docSnap.data().lifeAreas);
        const attachments = JSON.parse(docSnap.data().attachments);
        setAimData({
          areaForChange: formData.areaForChange,
          oldMe: formData.oldMe,
          newMe: formData.newMe,
          newMeDesc: formData.newMeDesc,
          lifeAreas: lifeAreas,
          journeyType: formData.journeyType,
          attachments: attachments
        })
      }
    });
  };

  const getMethodData = () => {
    const docRef = doc(db, "users", props.userId, "method", props.experimentId);
    getDoc(docRef).then(docSnap => {
      if (docSnap.exists()) {
        const reminder = JSON.parse(docSnap.data().reminder);
        const attachments = JSON.parse(docSnap.data().attachments);
        setMethodData({
          reminder: reminder,
          attachments: attachments
        })
      }
    });
  };

  const getConclusionData = () => {
    const docRef = doc(db, "sharing", props.userId, "experiment_shared_data", props.experimentId, "conclusion", props.experimentId);
    getDoc(docRef).then(docSnap => {
      if (docSnap.exists()) {
        const formData = JSON.parse(docSnap.data().formData);
        const stats = JSON.parse(docSnap.data().stats);
        const knowledgeStats = JSON.parse(stats.knowledgeStats);
        const selfEvolutionStats = JSON.parse(stats.selfEvolutionStats);
        const highlights = JSON.parse(formData.highlights);
        const reflectionStats = JSON.parse(stats.reflectionStats);
        const popularActivityStats = JSON.parse(stats.popularActivityStats);
        const attachments = JSON.parse(docSnap.data().attachments);
        setConclusionData({
          knowledgeStats: knowledgeStats,
          selfEvolutionStats: selfEvolutionStats,
          targetDestinationReached: formData.targetDestinationReached,
          distanceToDestination: formData.distanceToDestination,
          popularActivityStats: popularActivityStats,
          reflectionStats: reflectionStats,
          highlights: highlights,
          attachments: attachments,
        });
      }
    });
  };

  React.useEffect(() => {
    if(!props.error) {
      console.log(props.experimentId, props.userId, props.sharedSections)

      const auth = getAuth();
      signInWithEmailAndPassword(auth, "nguenaleksei2@gmail.com", "aleksei123!@#")
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        
        getExperimentData();
        getEventData();
        getOrientationData();
        getTransformationData();
        getAimData();
        getMethodData();
        getConclusionData();

        setTimeout(() => {
          setLoading(false);
        }, 4000);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
    }
  }, []);

  return (
    <div>
      {
        props.error ?
          <Error />
        :
          <div className="App">
            <Header experimentImage={experimentImage} experimentTitle={experimentTitle} />
            <FollowJourney 
              userName={userName}
            />
            <SubOptionTitle
              image="triggerBrand"
              title="What event has triggered me to$explore myself?"
              content="The trigger that gets a strong reaction from me & to explore myself futher is..."
              attachments={eventAttachments}
              userId={props.userId}
              experimentId={props.experimentId}
              type="feedback"
            />
            <MindContent 
              mindTravelEvent={mindTravelEvent}
              attentionReaction={attentionReaction}
              quoteText={quoteText} 
              eventChallenged={eventChallenged}
              eventImportance={eventImportance}
            />
            <SubOptionTitle
              image="locateBrand"
              title="Where am I located in life$space-time?"
              content="The current me pattern of being is..."
              attachments={orientationAttachments}
              userId={props.userId}
              experimentId={props.experimentId}
              type="orientation"
            />
            <CurrentContent 
              identityPart={identityPart}
              identityArea={identityArea}
              identityAspect={identityAspect}
              aspectProblem={aspectProblem}
              motivatorRelation={motivatorRelation}
              angleOfPerspective={angleOfPerspective}
              distance={distance}
              attentionEventExternal={attentionEventExternal}
              attentionEventInternal={attentionEventInternal}
              perspectiveResponse={perspectiveResponse}
              perceptionResponse={perceptionResponse}
              presenceResponse={presenceResponse}
            />
            <div style={{paddingTop: "50px"}}></div>
            <SubOptionTitle
              image="versionBrand"
              title="Where did this current version$of me originally come from?"
              content="The way I define & construct the who, what & how of me is..."
              attachments={transformationAttachments}
              userId={props.userId}
              experimentId={props.experimentId}
              type="transformation"
            />
            <VersionContent 
              identityPart={identityPart}
              motivatorRelation={motivatorRelation}
              firstAreaData={firstAreaData}
              secondAreaData={secondAreaData}
              thirdAreaData={thirdAreaData}
            />
            <SubOptionTitle
              image="powerBrand"
              title="Where is my will & power?"
              content="My will is being expressed & determined by my self concept which is..."
            />
            <DrivingContent 
              firstAreaData={firstAreaData}
              secondAreaData={secondAreaData}
              thirdAreaData={thirdAreaData}
            />
            { accepted === 1 && <Travel /> }
            { accepted === 1 &&
            <Experiment
              image="experimentBrand"
              title="Experimental change travel"
              content="I want to go on a self-change trip from the old me to..."
            /> }
            <SubOptionTitle
              image="aimBrand"
              title="Aim destination"
              content="The new me aim destination is comprised of:"
              attachments={aimData.attachments}
              userId={props.userId}
              experimentId={props.experimentId}
              type="aim"
            />
            <AimContent 
              data={aimData}
              identityPart={identityPart}
              firstAreaData={firstAreaData}
              secondAreaData={secondAreaData}
              thirdAreaData={thirdAreaData}
            />
            <div style={{paddingTop: "100px"}}></div>
            <SubOptionTitle
              image="methodBrand"
              title="Journey method"
              content="Eget ac nulla tellus suspendisse morbi et sit. Libero, ac pellentesque sapien purus condimentum eu ut non sed."
              attachments={methodData.attachments}
              userId={props.userId}
              experimentId={props.experimentId}
              type="method"
            />
            <MethodContent 
              methodData={methodData}
              conclusionData={conclusionData}
            />
            {
              conclusionData.highlights && conclusionData.highlights.length > 0 &&
              <HighlightContent
                highlights={conclusionData.highlights}
              />
            }
            <div style={{paddingTop: "100px"}}></div>
            <SubOptionTitle
              image="landingBrand"
              title="Landing conclusion"
              content="New me self change arrival... "
              attachments={conclusionData.attachments}
              userId={props.userId}
              experimentId={props.experimentId}
              type="conclusion"
            />
            <LandingContent 
              data={conclusionData}
            />
            <ArriveContent 
              data={conclusionData}
            />
            <StoreContent />
            <Common />
            <Footer />

            <Backdrop
              sx={{ color: '#000', background: "#fff",  zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
      }
    </div>
  );
}

export default App;
