import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chart from 'react-apexcharts';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dough from '../Chart/Dough';
import styles from './style.css';

const MethodContent = (props) => {
  const [knowledgeData, setKnowledgeData] = React.useState({
    series : [],
    options : {
      chart: {
        type: 'donut',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom'
          }
        }
      }],
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: false,   
      },
      colors: ['#21DF9B', '#1C2C53', '#1CB27C'],
      labels: [],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                formatter: function(val) {
                  return '';
                }
              },
              value: {
                show: true,
                ontSize: '14px',
                fontFamily: 'Inter',
                fontWeight: 400,
                color: '#FFFFFF',
              },
              total: {
                show: true,
                color: '#FFFFFF',
                formatter: function(w) {
                  return "Completed"
                }
              }
            }
          }
        }
      }
    }
  });
  const [selfEvolutionData, setSelfEvolutionData] = React.useState({
    series : [],
    options : {
      chart: {
        type: 'donut',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom'
          }
        }
      }],
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: false,   
      },
      colors: ['#21DF9B', '#1C2C53', '#1CB27C'],
      labels: [],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                formatter: function(val) {
                  return '';
                }
              },
              value: {
                show: true,
                ontSize: '14px',
                fontFamily: 'Inter',
                fontWeight: 400,
                color: '#FFFFFF',
              },
              total: {
                show: true,
                color: '#FFFFFF',
                formatter: function(w) {
                  return "Completed"
                }
              }
            }
          }
        }
      }
    }
  });
  const [presenceData, setPresenceData] = React.useState({
    series : [],
    options : {
      chart: {
        type: 'donut',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom'
          }
        }
      }],
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: false,   
      },
      colors: ['#184134', '#1A6D50', '#1C986C', '#1EC488', '#20EFA4'],
      labels: [],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: false,
                fontSize: '14px',
                fontFamily: 'Inter',
                fontWeight: 400,
                color: '#FFFFFF',
                formatter: function(val) {
                  
                }
              },
              value: {
                show: true,
                fontSize: '24px',
                fontFamily: 'Gordita',
                fontWeight: 900,
                color: '#FFFFFF',
                formatter: function(val, opt) {
                  return opt.globals.seriesPercent[opt.globals.series.indexOf(parseInt(val))] + "%";
                  // return 'of the time I’m close or being it'
                }
              }
            }
          }
        }
      }
    }
  });
  const [perspectiveData, setPerspectiveData] = React.useState({
    series : [],
    options : {
      chart: {
        type: 'donut',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom'
          }
        }
      }],
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: false,   
      },
      colors: ['#184134', '#1A6D50', '#1C986C', '#1EC488', '#20EFA4'],
      labels: [],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: false,
                fontSize: '14px',
                fontFamily: 'Inter',
                fontWeight: 400,
                color: '#FFFFFF',
                formatter: function(val) {
                  
                }
              },
              value: {
                show: true,
                fontSize: '24px',
                fontFamily: 'Gordita',
                fontWeight: 900,
                color: '#FFFFFF',
                formatter: function(val, opt) {
                  return opt.globals.seriesPercent[opt.globals.series.indexOf(parseInt(val))] + "%";
                  // return 'of the time I’m close or being it'
                }
              }
            }
          }
        }
      }
    }
  });
  const [perceptionData, setPerceptionData] = React.useState({
    series : [],
    options : {
      chart: {
        type: 'donut',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom'
          }
        }
      }],
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: false,   
      },
      colors: ['#184134', '#1A6D50', '#1C986C', '#1EC488', '#20EFA4'],
      labels: [],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: false,
                fontSize: '14px',
                fontFamily: 'Inter',
                fontWeight: 400,
                color: '#FFFFFF',
                formatter: function(val) {
                  
                }
              },
              value: {
                show: true,
                fontSize: '24px',
                fontFamily: 'Gordita',
                fontWeight: 900,
                color: '#FFFFFF',
                formatter: function(val, opt) {
                  return opt.globals.seriesPercent[opt.globals.series.indexOf(parseInt(val))] + "%";
                  // return 'of the time I’m close or being it'
                }
              }
            }
          }
        }
      }
    }
  });
  const [totalCompletedCount, setTotalCompletedCount] = React.useState(0);

  React.useEffect(() => {
    if(Object.keys(props.methodData.reminder).length > 0) {
      
    }
    if(Object.keys(props.conclusionData).length > 0) {
      const categoryData = JSON.parse(props.conclusionData.knowledgeStats.categoryCount);
      const categoryLabels = Object.keys(categoryData);
      const categoryCounts = Object.values(categoryData);
      setKnowledgeData({ 
        series: categoryCounts, 
        options: { 
          ...knowledgeData.options, 
          labels: categoryLabels,
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  name: {
                    formatter: function(val) {
                      return props.conclusionData.knowledgeStats.totalCount === 0 ? "0%" : Math.round(props.conclusionData.knowledgeStats.completedCount / props.conclusionData.knowledgeStats.totalCount * 100) + "%";
                    }
                  },
                }
              }
            }
          }
        } 
      });

      const categoryData1 = JSON.parse(props.conclusionData.selfEvolutionStats.categoryCount);
      const categoryLabels1 = Object.keys(categoryData1);
      const categoryCounts1 = Object.values(categoryData1);
      setSelfEvolutionData({ 
        series: categoryCounts1, 
        options: { 
          ...selfEvolutionData.options, 
          labels: categoryLabels1,
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  name: {
                    formatter: function(val) {
                      return props.conclusionData.selfEvolutionStats.totalCount === 0 ? "0%" : Math.round(props.conclusionData.selfEvolutionStats.completedCount / props.conclusionData.selfEvolutionStats.totalCount * 100) + "%";
                    }
                  },
                }
              }
            }
          }
        } 
      });

      setTotalCompletedCount(props.conclusionData.popularActivityStats.totalActivityCount);
      
      const universalSelfEntries = JSON.parse(props.conclusionData.reflectionStats.universalSelfEntries);
      const progressArray = ["Extremely far away", "Very far away", "Somewhat far", "Close", "I’m here"];
      const progressColor = ['#184134', '#1A6D50', '#1C986C', '#1EC488', '#20EFA4'];
      const values = Object.values(universalSelfEntries);
      if(values.length > 0) {
        values.forEach((item, index) => {
          const occurrences = item.reduce(function (acc, curr) {
            return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
          }, {});
          
          const keys = Object.keys(occurrences);
          const data = Object.values(occurrences);
          const labels = [];
          const colors = [];
          keys.forEach((item) => {
            labels.push(progressArray[item]);
            colors.push(progressColor[item]);
          });

          if(index === 0) {
            setPresenceData({ series: data, colors: colors, options: { ...presenceData.options, labels: labels } });
          } else if(index === 1) {
            setPerspectiveData({ series: data, colors: colors, options: { ...perspectiveData.options, labels: labels } });
          } else {
            setPerceptionData({ series: data, colors: colors, options: { ...perceptionData.options, labels: labels } });
          }
        });
      }
    }
  }, [props]);

  return (
    <Box sx={{ flexGrow: 1}} className="MethodContentClass">
      <Grid container spacing={1} sx={{px: '5%'}}>
        <Grid item md={12} xs={12} className="containerBox">
          <Typography className="activity_title">
            {totalCompletedCount} activities completed
          </Typography>
        </Grid>

        <Grid item md={4} xs={12}>
          <Card className="card1">
            <CardContent sx={{width: '100%', padding: "unset"}}>
              <img className='method1' src="/images/method1.png" alt="method1" />
              <Typography className="title">
                Knowledge gathering
              </Typography>
              <Box className="card1Box">
                <Typography className="title">
                  <img src="/images/youtubeIcon.png" alt="youtube" />YouTube video title
                </Typography>
                <Typography className="title">
                <img src="/images/arrowIcon1.png" alt="arrow1" />Repeats every Thursday at 12:00
                </Typography>
              </Box>
              <Box className="card1Box">
                <Typography className="title">
                  <img src="/images/youtubeIcon.png" alt="youtube" />Podcast title
                </Typography>
                <Typography className="title">
                <img src="/images/arrowIcon1.png" alt="arrow1" />Repeats every Thursday at 12:00
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4} xs={12}>
          <Card className="card1">
            <CardContent sx={{width: '100%', padding: "unset"}}>
              <img className='method2' src="/images/method2.png" alt="method2" />
              <Typography className="title">
                Self evolution activities
              </Typography>
              <Box className="card1Box">
                <Typography className="title">
                  <img src="/images/messageIcon.png" alt="message" />Find a calming podcast
                </Typography>
                <Typography className="title">
                <img src="/images/arrowIcon1.png" alt="arrow1" />Repeats every Thursday at 12:00
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4} xs={12}>
          <Card className="card1">
            <CardContent sx={{width: '100%', padding: "unset"}}>
              <img className='method3' src="/images/method3.png" alt="method3" />
              <Typography className="title">
                Awareness
              </Typography>
              <Box className="card1Box">
                <Typography className="title">
                  <img src="/images/youtubeIcon.png" alt="youtube" />Reflect on my progress
                </Typography>
                <Typography className="title">
                <img src="/images/eyeIcon1.png" alt="eye1" />Repeats every Thursday at 12:00
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={6} xs={12}>
          <Card className="card2">
            <CardContent>
              <Typography className="title">
                Knowledge gathering
              </Typography>
              <Typography className="subtitle">
                Health, Wealth, Family
              </Typography>
              <Box className="card2Box">
                <Chart options={knowledgeData.options} series={knowledgeData.series} type="donut" height={300}/>
                <Box className="card2Subbox">
                  {
                    knowledgeData.options.labels.map((item, index) => {
                      return (
                        <div key={index} style={{marginBottom: "8px"}}>
                          <span className="legend-marker" rel="1"></span>
                          <span className="content">{item}</span>
                        </div>
                      );
                    })
                  }
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item md={6} xs={12}>
          <Card className="card2">
            <CardContent>
              <Typography className="title">
                Self evolution activities
              </Typography>
              <Typography className="subtitle">
                Health, Wealth, Family
              </Typography>
              <Box className="card2Box">
                <Chart options={selfEvolutionData.options} series={selfEvolutionData.series} type="donut" height={300}/>
                <Box className="card2Subbox">
                  {
                    selfEvolutionData.options.labels.map((item, index) => {
                      return (
                        <div key={index} style={{marginBottom: "8px"}}>
                          <span className="legend-marker" rel="1"></span>
                          <span className="content">{item}</span>
                        </div>
                      );
                    })
                  }
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4} xs={12}>
          <Card className="card1">
            <CardContent>
              <Typography className="title">
                Presence & Awareness
              </Typography>
              <Typography className="content">
                My distance to universal self presence experience:
              </Typography>
              <Chart options={presenceData.options} series={presenceData.series} type="donut" height={300} />
              <Box className="card1Box1">
                <Typography className="text">
                  <span><span className="circle" style={{background: '#184134'}} rel="1"></span>1 - Extremely far away</span>
                  <span><span className="circle" style={{background: '#1A6D50'}} rel="1"></span>2 - Very far away</span>
                  <span><span className="circle" style={{background: '#1C986C'}} rel="1"></span>3 - Somewhat far</span>
                  <span><span className="circle" style={{background: '#1EC488'}} rel="1"></span>4 - Close</span>
                  <span><span className="circle" style={{background: '#20EFA4'}} rel="1"></span>5 - I’m here</span>
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4} xs={12}>
          <Card className="card1">
            <CardContent>
              <Typography className="title">
                Perspective & Awareness
              </Typography>
              <Typography className="content">
                My distance to universal self persepctive experience:
              </Typography>
              <Chart options={perspectiveData.options} series={perspectiveData.series} type="donut" height={300}/>
              <Box className="card1Box1">
                <Typography className="text">
                  <span><span className="circle" style={{background: '#184134'}} rel="1"></span>1 - Extremely far away</span>
                  <span><span className="circle" style={{background: '#1A6D50'}} rel="1"></span>2 - Very far away</span>
                  <span><span className="circle" style={{background: '#1C986C'}} rel="1"></span>3 - Somewhat far</span>
                  <span><span className="circle" style={{background: '#1EC488'}} rel="1"></span>4 - Close</span>
                  <span><span className="circle" style={{background: '#20EFA4'}} rel="1"></span>5 - I’m here</span>
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4} xs={12}>
          <Card className="card1">
            <CardContent>
              <Typography className="title">
                Perception & Awareness
              </Typography>
              <Typography className="content">
                My distance to universal self perception experience:
              </Typography>
              <Chart options={perceptionData.options} series={perceptionData.series} type="donut" height={300}/>
              <Box className="card1Box1">
                <Typography className="text">
                  <span><span className="circle" style={{background: '#184134'}} rel="1"></span>1 - Extremely far away</span>
                  <span><span className="circle" style={{background: '#1A6D50'}} rel="1"></span>2 - Very far away</span>
                  <span><span className="circle" style={{background: '#1C986C'}} rel="1"></span>3 - Somewhat far</span>
                  <span><span className="circle" style={{background: '#1EC488'}} rel="1"></span>4 - Close</span>
                  <span><span className="circle" style={{background: '#20EFA4'}} rel="1"></span>5 - I’m here</span>
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default MethodContent;