import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import styles from './style.css';

const Header = (props) => {
  return (
    <Box sx={{ flexGrow: 1}} className="headerClass">
      <Grid container>
        <Grid item md={5} xs={12}>
          <img className="left_mountain" src={props.experimentImage} alt="mountain" style={{ display: props.experimentImage === "" ? "none" : 'block' }} />
        </Grid>
        <Grid item md={props.experimentImage === "" ? 12 : 7} xs={12}>
          <img className="logo" src="/images/Logo.png" />
          <Typography className="title">
            {props.experimentTitle}
          </Typography>
          <Typography className="time">
            <img className="clock" src="/images/clock.png"/> Started 25/05/21 - 25/11/21
          </Typography>
          <Typography className="content">
            I wanted to find out intelligence about me to trace one event in my life to how I define who, what and how I am being. So I went on a FOQ U ‘I’ trip to be more self aware and evolve my self model i.e. how I view myself, reality and interact with life. Here is the tale of my travel adventure:
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Header;