import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import ReactAudioPlayer from 'react-audio-player';
import styles from './style.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const HighlightContent = (props) => {
  const [postReflectionMedia, setPostReflectionMedia] = React.useState({
    type: 0,
    label: "",
    icon: "",
    url: ""
  });
  const [preReflectionMedia, setPreReflectionMedia] = React.useState({
    type: 0,
    label: "",
    icon: "",
    url: ""
  });
  const [open, setOpen] = React.useState(false);
  const [dialogFlag, setDialogFlag] = React.useState(false);

  const handleClickOpen = (flag) => {
    setOpen(true);
    setDialogFlag(flag);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if(props.highlights.length > 0) {
      const contentArray = ["Listen to the audio", "View the image", "Watch the video", "View the text"];
      const imageArray = ["/images/audio.png", "/images/image.png", "/images/video.png", "/images/text.png"];
      const postReflectionMediaObj = JSON.parse(props.highlights[0].postReflectionMedia);
      let keys = Object.keys(postReflectionMediaObj);
      let values = Object.values(postReflectionMediaObj);
      let type;
      switch(keys[0]) {
      case "audio":
        type = 0;
        break;
      case "image":
        type = 1;
        break;
      case "video":
        type = 2;
        break;
      default:
        type = 3;
        break;
      }
      setPostReflectionMedia({
        type: type,
        label: contentArray[type],
        icon: imageArray[type],
        url: values[0]
      });

      const preReflectionMediaObj = JSON.parse(props.highlights[0].preReflectionMedia);
      keys = Object.keys(preReflectionMediaObj);
      values = Object.values(preReflectionMediaObj);
      switch(keys[0]) {
      case "audio":
        type = 0;
        break;
      case "image":
        type = 1;
        break;
      case "video":
        type = 2;
        break;
      default:
        type = 3;
        break;
      }
      setPreReflectionMedia({
        type: type,
        label: contentArray[type],
        icon: imageArray[type],
        url: values[0]
      });
    }
  }, [props]);

  return (
    <Box sx={{ flexGrow: 1}} className="HighlightContentClass">
      <Grid container spacing={1} sx={{px: '5%'}}>
        <Grid item md={12} xs={12} className="journeyItem">
          <img className="journeyBrand" src="/images/journeyBrand.png" alt="journeyBrand" />
          <Typography className="bigTitle">
            Experiential results
          </Typography>
          <Stack className="loadstack" direction="row">
            <img className="load" src="/images/loadIcon.png" alt="load" />
            <Typography className="title">
              Journey highlights
            </Typography>
          </Stack>
          <Typography className="content">
            My top 3 picks of my journey are:
          </Typography>
        </Grid>

        <Grid item md={4} xs={12} sx={{mt: "20px"}}>
          <Card className="card3">
            <CardContent>
              <Stack className="stack" direction="row">
                <Button variant="outlined">Hard decision</Button>
                <img className="rightArrow" src="/images/rightArrowIcon.png" alt="rightArrow" /> 
              </Stack>
              <Stack className="stack1">
                <img className="downArrow" src="/images/downArrowIcon.png" alt="downArrow" /> 
              </Stack>
              <Stack direction="row">
                <Button variant="outlined">useful insight</Button>
              </Stack>
              <Stack className="stack2" direction="row" onClick={(e) => handleClickOpen(false)}>
                <img className="mic" src={postReflectionMedia.icon} alt="mic" /> 
                <Typography className="content">
                  {postReflectionMedia.label}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4} xs={12} sx={{mt: "20px"}}>
          <Card className="card3">
            <CardContent>
              <Stack className="stack" direction="row">
                <Button variant="outlined">Hard decision</Button>
                <img className="rightArrow" src="/images/rightArrowIcon.png" alt="rightArrow" /> 
              </Stack>
              <Stack className="stack1">
                <img className="downArrow" src="/images/downArrowIcon.png" alt="downArrow" /> 
              </Stack>
              <Stack direction="row">
                <Button variant="outlined">useful insight</Button>
              </Stack>
              <Stack className="stack2" direction="row" onClick={(e) => handleClickOpen(true)}>
                <img className="mic" src={preReflectionMedia.icon} alt="mic" /> 
                <Typography className="content">
                  {preReflectionMedia.label}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        
      </Grid>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="highlightDialog"
        maxWidth="80%"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        </BootstrapDialogTitle>
        <DialogContent>
          <Stack className="loadstack" direction="row">
            <img className="load" src="/images/loadIcon.png" alt="load" />
            <Typography className="title">
              Journey highlight
            </Typography>
          </Stack>
          <Box className="dialogBox">
            {
              dialogFlag ?
                preReflectionMedia.type === 0 ?
                  <ReactAudioPlayer
                    src={preReflectionMedia.url}
                    autoPlay
                    controls
                  />
                :
                  preReflectionMedia.type === 1 ?
                    <img className='dialogImg' src={preReflectionMedia.url} />
                  :
                    preReflectionMedia.type === 2 ?
                      <video className='dialogVideo' controls>
                        <source src={preReflectionMedia.url} />
                        Your browser does not support the audio element.
                      </video>
                    :
                      <Typography className="dialogText">
                        {preReflectionMedia.url}
                      </Typography>
              :
                postReflectionMedia.type === 0 ?
                  <ReactAudioPlayer
                    src={postReflectionMedia.url}
                    autoPlay
                    controls
                  />
                :
                  postReflectionMedia.type === 1 ?
                    <img className='dialogImg' src={postReflectionMedia.url} />
                  :
                  postReflectionMedia.type === 2 ?
                      <video className='dialogVideo' controls>
                        <source src={postReflectionMedia.url} />
                        Your browser does not support the audio element.
                      </video>
                    :
                      <Typography className="dialogText">
                        {postReflectionMedia.url}
                      </Typography>
            }
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  )
}

export default HighlightContent;