import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import orientationJson from '../../mappings/orientation.json';
import transformationJson from '../../mappings/transformation.json';
import styles from './style.css';

const VersionContent = (props) => {
  const [identityPartHeader, setIdentityPartHeader] = React.useState("");
  const [motivatorRelation , setMotivatorRelation ] = React.useState("");
  const [content, setContent] = React.useState("");
  const [content1, setContent1] = React.useState("");
  const [content2, setContent2] = React.useState("");
  const [original, setOriginal] = React.useState("");
  const [original1, setOriginal1] = React.useState("");
  const [original2, setOriginal2] = React.useState("");
  const [lockedReason, setLockedReason] = React.useState("");
  const [lockedReason1, setLockedReason1] = React.useState("");
  const [lockedReason2, setLockedReason2] = React.useState("");

  React.useEffect(() => {
    const identityPartObj = orientationJson.mindLocationData.identityPart.find(o => o.value === props.identityPart);
    setIdentityPartHeader(identityPartObj.category);

    const motivatorRelationObj = orientationJson.mindLocationData.primeMotivator[0].motivatorRelation.find(o => o.value === props.motivatorRelation);
    setMotivatorRelation(motivatorRelationObj.text);

    let attitudeObj;
    if(props.firstAreaData.state === 2) {
      attitudeObj = transformationJson["mindConstruct-data"].attitude.find(o => o.value === props.firstAreaData.attitude);
      setContent(attitudeObj.text);
    } else {
      setContent(props.firstAreaData.attribute);
    }
    if(props.secondAreaData.state === 2) {
      attitudeObj = transformationJson["mindConstruct-data"].attitude.find(o => o.value === props.secondAreaData.attitude);
      setContent(attitudeObj.text);
    } else {
      setContent1(props.secondAreaData.attribute);
    }
    if(props.thirdAreaData.state === 2) {
      attitudeObj = transformationJson["mindConstruct-data"].attitude.find(o => o.value === props.thirdAreaData.attitude);
      setContent(attitudeObj.text);
    } else {
      setContent2(props.thirdAreaData.attribute);
    }

    let mindForceObj;
    if(props.firstAreaData.mindForce.value >= 0) {
      mindForceObj = transformationJson["mindConstruct-data"].mindForce.find(o => o.value === props.firstAreaData.mindForce.value);
      if(props.firstAreaData.mindForce.value === 99) {
        setOriginal(mindForceObj.customInput);
      } else {
        if(mindForceObj.additionalInput) {
          const input = JSON.parse(props.firstAreaData.mindForce.additionalInput);
          const additionalInputObj = mindForceObj.additionalInput.find(o => o.value === input.value);
          setOriginal(additionalInputObj.text);
        } else {
          setOriginal(mindForceObj.text);
        }
      }
    }
    if(props.secondAreaData.mindForce.value >= 0) {
      mindForceObj = transformationJson["mindConstruct-data"].mindForce.find(o => o.value === props.secondAreaData.mindForce.value);
      if(props.secondAreaData.mindForce.value === 99) {
        setOriginal1(mindForceObj.customInput);
      } else {
        if(mindForceObj.additionalInput) {
          const input = JSON.parse(props.secondAreaData.mindForce.additionalInput);
          const additionalInputObj = mindForceObj.additionalInput.find(o => o.value === input.value);
          setOriginal1(additionalInputObj.text);
        } else {
          setOriginal1(mindForceObj.text);
        }
      }
    }
    if(props.thirdAreaData.mindForce.value >= 0) {
      mindForceObj = transformationJson["mindConstruct-data"].mindForce.find(o => o.value === props.thirdAreaData.mindForce.value);
      if(props.thirdAreaData.mindForce.value === 99) {
        setOriginal2(mindForceObj.customInput);
      } else {
        if(mindForceObj.additionalInput) {
          const input = JSON.parse(props.thirdAreaData.mindForce.additionalInput);
          const additionalInputObj = mindForceObj.additionalInput.find(o => o.value === input.value);
          setOriginal2(additionalInputObj.text);
        } else {
          setOriginal2(mindForceObj.text);
        }
      }
    }

    let lockedReasonObj = transformationJson["mindConstruct-data"].lockedReason.find(o => o.value === props.firstAreaData.lockedReason);
    setLockedReason(lockedReasonObj.text);
    transformationJson["mindConstruct-data"].lockedReason.find(o => o.value === props.secondAreaData.lockedReason);
    setLockedReason1(lockedReasonObj.text);
    transformationJson["mindConstruct-data"].lockedReason.find(o => o.value === props.thirdAreaData.lockedReason);
    setLockedReason2(lockedReasonObj.text);
  }, [props]);

  return (
    <Box sx={{ flexGrow: 1, mt: "50px"}} className="VersionContentClass">
      <Grid container>
        <Grid item md={5} xs={12}>
          <img className='qIcon1' src="/images/qIcon1.png" alt="qIcon1" />
          <Typography className="title">
            How do I see myself?
          </Typography>
          <Card className="card">
            <CardContent>
              <Typography className="title">
                {content}
              </Typography>
              <Typography className="content">
                of my 
              </Typography>
              <Typography className="title">
                {identityPartHeader}
              </Typography>
              <Typography className="content">
                from the point of view of:
              </Typography>
              <Typography className="title">
                {motivatorRelation}
              </Typography>
              <Typography className="content">
                which is the law of my being & rules over what I can be.
              </Typography>
              <Typography className="content">
                That originally came from
              </Typography>
              <Typography className="title">
                {
                  props.firstAreaData.mindForce.value == 9 && "science"
                }
                {
                  props.firstAreaData.mindForce.value == 9 && <br/>
                }
                {original}
              </Typography>
              <Typography className="content">
                I stay the same because
              </Typography>
              <Typography className="title">
                {lockedReason}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={7} xs={12}>
          <img className="version1" src="/images/version1.png" />
        </Grid>

        <Grid item md={7} xs={12} sx={{mt: "68px"}}>
          <img className="version2" src="/images/version2.png" />
        </Grid>
        <Grid item md={5} xs={12} sx={{mt: "68px"}}>
          <img className='qIcon2' src="/images/qIcon2.png" alt="qIcon2" />
          <Typography className="title">
            How do I see my reality?
          </Typography>
          <Card className="card">
            <CardContent>
              <Typography className="title">
                {content1}
              </Typography>
              <Typography className="content">
                of my 
              </Typography>
              <Typography className="title">
                outside in<br/>
                physical, material, past
              </Typography>
              <Typography className="content">
                space-time universe of reality which sets the boundaries & energy I live by.<br/>
                That originally came from
              </Typography>
              <Typography className="title">
                {
                  props.secondAreaData.mindForce.value == 9 && "science"
                }
                {
                  props.secondAreaData.mindForce.value == 9 && <br/>
                }
                {original1}
              </Typography>
              <Typography className="content">
                I stay the same because
              </Typography>
              <Typography className="title">
                {lockedReason1}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={5} xs={12} sx={{mt: "68px"}}>
          <img className='qIcon3' src="/images/qIcon3.png" alt="qIcon3" />
          <Typography className="title">
            How do I interact with life?
          </Typography>
          <Card className="card">
            <CardContent>
              <Typography className="title">
                {content2}
              </Typography>
              <Typography className="content">
                that influences my
              </Typography>
              <Typography className="title">
                Subjective mind<br/>
                from the past
              </Typography>
              <Typography className="content">
                which guides my behaviour & activities in everyday life.<br/>
                That originally came from
              </Typography>
              <Typography className="title">
                {
                  props.thirdAreaData.mindForce.value == 9 && "science"
                }
                {
                  props.thirdAreaData.mindForce.value == 9 && <br/>
                }
                {original2}
              </Typography>
              <Typography className="content">
                I stay the same because
              </Typography>
              <Typography className="title">
                {lockedReason2}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={7} xs={12} sx={{mt: "68px"}}>
          <img className="version1" src="/images/version1.png" />
        </Grid>
      </Grid>
    </Box>
  )
}

export default VersionContent;