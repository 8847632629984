import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import styles from './style.css';

const FollowJourney = (props) => {
  return (
    <Box sx={{ flexGrow: 1}} className="FollowJourneyClass">
      <Grid container>
        <Grid item md={12} xs={12}>
          <img className="science" src="/images/science_circle.png" alt="science" />
          <Typography className="name">
            Follow{ props.userName === "" ? " " : ` ${props.userName}'s `}journey
          </Typography>
          <img className="line" src="/images/Line1.png" alt="line" />
          <br/>
        </Grid>
      </Grid>
    </Box>
  )
}

export default FollowJourney;