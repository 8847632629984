import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chart from 'react-apexcharts';
import Stack from '@mui/material/Stack';
import progressJson from '../../mappings/add_progress.json';
import styles from './style.css';

const LandingContent = (props) => {
  const [chartData, setChartData] = React.useState({
    series : [],
    options : {
      chart: {
        type: 'donut',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom'
          }
        }
      }],
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: false,   
      },
      colors: ['#827251', '#A18A5C', '#D1B06D', '#EEC676', '#F8CE7A'],
      labels: [],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: false,
                fontSize: '14px',
                fontFamily: 'Inter',
                fontWeight: 400,
                color: '#FFFFFF',
                formatter: function(val) {
                  
                }
              },
              value: {
                show: true,
                fontSize: '24px',
                fontFamily: 'Gordita',
                fontWeight: 900,
                color: '#FFFFFF',
                formatter: function(val, opt) {
                  return opt.globals.seriesPercent[opt.globals.series.indexOf(parseInt(val))] + "%";
                }
              }
            }
          }
        }
      }
    }
  });

  React.useEffect(() => {
    if(Object.keys(props.data).length > 0) {
      const limitationEntries = JSON.parse(props.data.reflectionStats.limitationEntries);
      const values = Object.values(limitationEntries);
      const occurrences = values.reduce(function (acc, curr) {
        return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
      }, {});
      
      const keys = Object.keys(occurrences);
      const data = Object.values(occurrences);
      const labels = [];
      keys.forEach((item) => {
        const progressObj = progressJson.selfLimitation.find(o => o.value === parseInt(item));
        labels.push(progressObj.text);
      });
      setChartData({ series: data, options: { ...chartData.options, labels: labels } });
    }
  }, [props]);

  return (
    <Box sx={{ flexGrow: 1}} className="LandingContentClass">
      <Grid container spacing={1} className="container">
        <Grid item md={8} xs={12}>
          <Card className="card">
            <CardContent>
              <Stack direction="row" className="stack">
                <Box className="box">
                  <Typography className="title">
                    Did I go beyond the limits of the old me? 
                  </Typography>
                  <Typography className="content">
                    The power of my will experience is....
                  </Typography>
                </Box>
                <div>
                  <Chart options={chartData.options} series={chartData.series} type="donut" height={300}/>
                  <Box className="cardBox">
                    <Typography className="text">
                      {
                        chartData.options.labels.map((item, index) => {
                          return (
                            <span key={index}><span className="circle" style={{background: chartData.options.colors[index]}} rel="1"></span>{index+1} - {item}</span>
                          )
                        })
                      }
                    </Typography>
                  </Box>
                </div>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LandingContent;