import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import styles from './style.css';

const Common = () => {
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);

  const handleClick = (index) => {
    switch(index) {
    case 1:
      setOpen(!open);
      break;
    case 2:
      setOpen1(!open1);
      break;
    case 3:
      setOpen2(!open2);
      break;
    default:
      setOpen3(!open3);
      break;
    }
  };

  return (
    <Box sx={{ flexGrow: 1}} className="CommonClass">
      <Grid container className='container'>
        <Grid item md={3} xs={12}>
          <img src="/images/common.png" alt="common" />
        </Grid>

        <Grid item md={7} xs={12}>
          <Typography className="title">
            Common questions & answers
          </Typography>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton onClick={() => handleClick(1)}>
              <ListItemText className="listTitle" primary="Maecenas tristique morbi velit sed risus penatibus sagittis mattis." />
              {open ? <RemoveIcon /> : <AddIcon />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText className="listContent" primary="At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis udis praesentium voluptatum deleniti atque excepturi sint occaecati cupiditate." />
                </ListItemButton>
              </List>
            </Collapse>
          </List>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton onClick={() => handleClick(2)}>
              <ListItemText className="listTitle" primary="Euismod massa tortor quam lobortis ullamcorper?" />
              {open1 ? <RemoveIcon /> : <AddIcon />}
            </ListItemButton>
            <Collapse in={open1} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText className="listContent" primary="At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis udis praesentium voluptatum deleniti atque excepturi sint occaecati cupiditate." />
                </ListItemButton>
              </List>
            </Collapse>
          </List>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton onClick={() => handleClick(3)}>
              <ListItemText className="listTitle" primary="Porttitor facilisis donec tortor neque lobortis?" />
              {open2 ? <RemoveIcon /> : <AddIcon />}
            </ListItemButton>
            <Collapse in={open2} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText className="listContent" primary="At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis udis praesentium voluptatum deleniti atque excepturi sint occaecati cupiditate." />
                </ListItemButton>
              </List>
            </Collapse>
          </List>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton onClick={() => handleClick(4)}>
              <ListItemText className="listTitle" primary="Cursus scelerisque urna bibendum id etiam at sit mi?" />
              {open3 ? <RemoveIcon /> : <AddIcon />}
            </ListItemButton>
            <Collapse in={open3} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText className="listContent" primary="At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis udis praesentium voluptatum deleniti atque excepturi sint occaecati cupiditate." />
                </ListItemButton>
              </List>
            </Collapse>
          </List>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Common;