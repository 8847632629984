import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDP2MPYv1d3KKV8bzCqQQO94H0b9Nz_HpI",
  authDomain: "foq-u-app.firebaseapp.com",
  databaseURL: "https://foq-u-app-default-rtdb.firebaseio.com",
  projectId: "foq-u-app",
  storageBucket: "foq-u-app.appspot.com",
  messagingSenderId: "163423857903",
  appId: "1:163423857903:web:efd31e764dbd2b8f81ff1a",
  measurementId: "G-M38N54XLGR"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage();
const db = getFirestore(app);

export {auth, db, storage};