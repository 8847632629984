import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import orientationJson from '../../mappings/orientation.json';
import eventJson from '../../mappings/feedback_event.json';
import styles from './style.css';

const CurrentContent = (props) => {
  const [identityPart, setIdentityPart] = React.useState("");
  const [identityPartHeader, setIdentityPartHeader] = React.useState("");
  const [identityArea, setIdentityArea] = React.useState("");
  const [identityAspect , setIdentityAspect ] = React.useState("");
  const [motivatorRelation , setMotivatorRelation ] = React.useState("");
  const [distance, setDistance ] = React.useState("");
  const [attentionEventExternal, setAttentionEventExternal] = React.useState("");
  const [attentionEventInternal, setAttentionEventInternal] = React.useState("");
  const [perceptionResponse, setPerceptionResponse] = React.useState("");
  const [perspectiveResponse, setPerspectiveResponse] = React.useState("");
  const [presenceResponse, setPresenceResponse] = React.useState("");
  
  React.useEffect(() => {
    const identityPartObj = orientationJson.mindLocationData.identityPart.find(o => o.value === props.identityPart);
    setIdentityPart(identityPartObj.text);
    setIdentityPartHeader(identityPartObj.category);

    const identityAreaObj = identityPartObj.identityArea.find(o => o.value === props.identityArea);
    setIdentityArea(identityAreaObj.text);

    const identityAspectObj = identityAreaObj.identityAspect.find(o => o.value === props.identityAspect);
    setIdentityAspect(identityAspectObj.text);

    const motivatorRelationObj = orientationJson.mindLocationData.primeMotivator[0].motivatorRelation.find(o => o.value === props.motivatorRelation);
    setMotivatorRelation(motivatorRelationObj.text);

    const distanceObj = orientationJson.mindLocationData.distance.find(o => o.value === props.distance);
    setDistance(distanceObj.text);

    const attentionEventExternalObj = eventJson.attentionEventExternal.find(o => o.value === props.attentionEventExternal);
    setAttentionEventExternal(attentionEventExternalObj.text);

    const attentionEventInternalObj = eventJson.attentionEventInternal.find(o => o.value === props.attentionEventInternal);
    setAttentionEventInternal(attentionEventInternalObj.text);

    const perceptionResponseObj = orientationJson.mindForceData.perceptionResponse.find(o => o.value === props.perceptionResponse);
    setPerceptionResponse(perceptionResponseObj.text);
    const perspectiveResponseObj = orientationJson.mindForceData.perspectiveResponse.find(o => o.value === props.perspectiveResponse);
    setPerspectiveResponse(perspectiveResponseObj.text);
    const presenceResponseObj = orientationJson.mindForceData.presenceResponse.find(o => o.value === props.presenceResponse);
    setPresenceResponse(presenceResponseObj.text);
  }, [props]);

  return (
    <Box sx={{ flexGrow: 1}} className="CurrentContentClass">
      <Grid container>
        <Grid item md={4} xs={12}>
          <img src="/images/qIcon1.png" alt="question" />
          <Typography className="questionTitle">
            How do I see myself?
          </Typography>
          <img className="current1" src="/images/current1.png" alt="current1" />
          <Card className="card">
            <CardContent>
              <Typography>
                At the centre of my being, is my:
              </Typography>
              <Typography className="bigTitle">
                {identityPartHeader}
              </Typography>
              <Typography>
                related to my {identityPart} & {identityArea}.
              </Typography>
            </CardContent>
          </Card>
          <Card className="card">
            <CardContent>
              <Typography>
                Because the way I view myself is based on my:
              </Typography>
              <Typography className="smallTitle">
                {identityAspect}
                <br/>
                {props.aspectProblem}
              </Typography>
              <Typography>
                from the point of view of:
              </Typography>
              <Typography className="smallTitle">
                {props.angleOfPerspective}
                <br/>
                {motivatorRelation}
                <br/>
                {distance}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4} xs={12}>
          <img className="question" src="/images/qIcon2.png" alt="question" />
          <Typography className="questionTitle">
            How do I see my reality?
          </Typography>
          <img className="current1" src="/images/current2.png" alt="current2" />
          <Card className="card">
            <CardContent>
              <Typography>
                I am living in a 
              </Typography>
              <Typography className="bigTitle">
                physical<br/>material &<br/>past
              </Typography>
              <Typography>
                reality that I formed, based on a physical memory space-time construct.
              </Typography>
            </CardContent>
          </Card>
          <Card className="card">
            <CardContent>
              <Typography>
                The way I process information is based on:
              </Typography>
              <Typography className="smallTitle">
                the outside world  &<br/>
                my old existing physical body &<br/>
                personality mind
              </Typography>
              <Typography>
                from the perspective of:
              </Typography>
              <Typography className="smallTitle">
                {attentionEventExternal}<br/>
                {motivatorRelation}<br/>
                personal {attentionEventInternal}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4} xs={12}>
          <img className="question" src="/images/qIcon3.png" alt="question" />
          <Typography className="questionTitle">
            How do I interact with life?
          </Typography>
          <img className="current1" src="/images/current3.png" alt="current3" />
          <Card className="card">
            <CardContent>
              <Typography>
                I am expressing my being as a:
              </Typography>
              <Typography className="bigTitle">
                subjective mind from the past
              </Typography>
              <Typography>
                operating as a fractured universal self in life.
              </Typography>
            </CardContent>
          </Card>
          <Card className="card">
            <CardContent>
              <Typography>
                Because the way I interpret information is based on being a:
              </Typography>
              <Typography className="smallTitle">
                physical consumer
              </Typography>
              <Typography>
                of:
              </Typography>
              <Typography className="smallTitle">
                outside in material reality
              </Typography>
              <Typography>
                presently living in:
              </Typography>
              <Typography className="smallTitle">
                {perceptionResponse}<br/>
                {perspectiveResponse}<br/>
                {presenceResponse} 
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
      </Grid>
    </Box>
  )
}

export default CurrentContent;