import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import styles from './style.css';

const Travel = () => {
  return (
    <Box sx={{ flexGrow: 1}} className="TravelClass">
      <Grid container>
        <Grid item md={12} xs={12}>
          <img className="travel" src="/images/travel.png" alt="travel" />
          <Typography className="title">
            Travelling to a higher level of being...
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Travel;