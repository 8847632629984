import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import styles from './style.css';

const Footer = () => {
  return (
    <Box sx={{ flexGrow: 1}} className="FooterClass">
      <Grid container>
        <Grid item md={12} xs={12}>
          <img className="logo" src="/images/Logo.png" alt="logo" />
          <Stack spacing={2} direction="row" className="stack">
            <Typography className="title">
              Privacy policy
            </Typography>
            <Typography className="title">
              Terms & conditions
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Footer;