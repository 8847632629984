import React from 'react';
import ReactDOM from 'react-dom/client';
import './fonts/Inter-VariableFont_slnt,wght.ttf';
import './fonts/Type Atelier - Gordita Black.otf';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { doc, getDoc } from 'firebase/firestore/lite';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { db } from './firebase';


///sharing/x9H6MEtNgxgRxcYfNXOOQHD54wz2/experiment_share_link/1669941399742618

const userId = window.location.pathname.split('/')[1];
const linkId = window.location.pathname.split('/')[2];

const auth = getAuth();
signInWithEmailAndPassword(auth, "nguenaleksei2@gmail.com", "aleksei123!@#")
.then((userCredential) => {
  const docRef = doc(db, "sharing", userId, "experiment_share_link", linkId);
  getDoc(docRef).then(docSnap => {
    let error = false;
    let experimentId = "", userId = "", sharedSections = {};
    if (docSnap.exists()) {
      experimentId = docSnap.data().experimentId;
      userId = docSnap.data().userId;
      sharedSections = docSnap.data().sharedSections;   
    } else {
      error = true;
    }
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <App error={error} experimentId={experimentId} userId={userId} sharedSections={sharedSections} />
      </React.StrictMode>
    );
  });
})
.catch((error) => {
  const errorCode = error.code;
  const errorMessage = error.message;
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
