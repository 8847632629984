import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import { storage } from '../../firebase';
import { ref, getDownloadURL } from "firebase/storage";
import ReactAudioPlayer from 'react-audio-player';
import styles from './style.css';

const SubOptionTitle = (props) => {
  const [video, setVideo] = React.useState("");
  const [audio, setAudio] = React.useState("");
  const [text, setText] = React.useState("");
  const [image, setImage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState(0);
  
  const handleClose = () => {
    setOpen(false);
  };

  const downloadURI = (uri, name) => {
      var link = document.createElement("a");
      link.setAttribute('download', name);
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      link.remove();
  }

  const handleVideo = () => {
    if(video.includes("firebasestorage.googleapis.com")) {
      downloadURI(video, 'video');
      setType(0);
      setOpen(true);
    } else {
      const filename = video.split("/")[video.split("/").length - 1];
      const link = 'gs://foq-u-app.appspot.com/' + props.userId + '/' + props.experimentId + '/' + props.type + '/' + filename;
      getDownloadURL(ref(storage, link))
      .then((url) => {
        setVideo(url);
        downloadURI(url, filename);
        setType(0);
        setOpen(true);
      })
      .catch((error) => {
        // Handle any errors
      });
    }
  };
  
  const handleAudio = () => {
    if(audio.includes("firebasestorage.googleapis.com")) {
      downloadURI(audio, 'audio');
      setType(1);
      setOpen(true);
    } else {
      const filename = audio.split("/")[audio.split("/").length - 1];
      const link = 'gs://foq-u-app.appspot.com/' + props.userId + '/' + props.experimentId + '/' + props.type + '/' + filename;
      getDownloadURL(ref(storage, link))
      .then((url) => {
        setAudio(url);
        downloadURI(url, filename);
        setType(1);
        setOpen(true);
      })
      .catch((error) => {
        // Handle any errors
      });
    }
  };

  const handleText = () => {
    setType(2);
    setOpen(true);
  };

  const handleImage = () => {
    if(image.includes("firebasestorage.googleapis.com")) {
      downloadURI(image, 'image');
      setType(3);
      setOpen(true);
    } else {
      const filename = image.split("/")[image.split("/").length - 1];
      const link = 'gs://foq-u-app.appspot.com/' + props.userId + '/' + props.experimentId + '/' + props.type + '/' + filename;
      getDownloadURL(ref(storage, link))
      .then((url) => {
        setImage(url);
        downloadURI(url, filename);
        setType(3);
        setOpen(true);
      })
      .catch((error) => {
        // Handle any errors
      });
    }
  };

  React.useEffect(() => {
    if(props.attachments) {
      const keys = Object.keys(props.attachments);
      const values = Object.values(props.attachments);

      keys.forEach((item, index) => {
        switch(item) {
        case "video":
          setVideo(values[index]);
          break;
        case "audio":
          setAudio(values[index]);
          break;
        case "text":
          setText(values[index]);
          break;
        case "image":
          setImage(values[index]);
          break;
        default:
          break;
        }
      });
    }
  }, [props]);

  return (
    <div className="SubOptionTitleClass">
      <Box sx={{ flexGrow: 1}}>
        <Grid container>
          <Grid item md={12} xs={12}>
            <img className="brand" src={`/images/${props.image}.png`} alt="brand" />

            <Typography className="title">
              {props.title.split("$").map((i, key) => {
                return <div key={key}>{i}</div>;
              })}
            </Typography>
            <Typography className="content">
              {props.content.split("$").map((i, key) => {
                return <div key={key}>{i}</div>;
              })}
            </Typography>
            <Typography className="itemTitle">
              { props.attachments && Object.keys(props.attachments).length > 0 && "My ‘i’ travel trip experience:" }
            </Typography>
            <ButtonGroup variant="outlined" aria-label="outlined button group" className="buttonGroup">
              { video && <img className="video" src="/images/videoIcon.png" alt="video" onClick={handleVideo} /> }
              { audio && <img className="voice" src="/images/voiceIcon.png" alt="voice" onClick={handleAudio} /> }
              { text && <img className="text" src="/images/textIcon.png" alt="text" onClick={handleText} /> }
              { image && <img className="image" src="/images/imageIcon.png" alt="image" onClick={handleImage} /> }
            </ButtonGroup>
          </Grid>
        </Grid>
      </Box>
      <Dialog onClose={handleClose} open={open} className="dialog">
        {
          type == 0 &&
          <video controls>
            <source src={video} />
            Your browser does not support the audio element.
          </video>
        }
        {
          type == 1 &&
          <ReactAudioPlayer
            src={audio}
            autoPlay
            controls
          />
        }
        {
          type == 2 &&
          <Typography className="dialogText">
            {text}
          </Typography>
        }
        {
          type == 3 &&
          <img src={image} alt="image" />
        }
      </Dialog>
    </div>
  )
}

export default SubOptionTitle;